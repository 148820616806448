export const DOCTOR_FETCH_INIT = "DOCTOR_FETCH_INIT";
export const DOCTOR_FETCH_SUCCESS = "DOCTOR_FETCH_SUCCESS";
export const DOCTOR_FETCH_FAIL = "DOCTOR_FETCH_FAIL";
export const FETCH_TOP_RATED_DOCTOR_INIT = "FETCH_TOP_RATED_DOCTOR_INIT";
export const FETCH_TOP_RATED_DOCTOR_SUCCESS = "FETCH_TOP_RATED_DOCTOR_SUCCESS";
export const FETCH_TOP_RATED_DOCTOR_FAIL = "FETCH_TOP_RATED_DOCTOR_FAIL";
//plopImport
export const SET_DOCTOR_ACCOUNT_STATUS_INIT = "SET_DOCTOR_ACCOUNT_STATUS_INIT";
export const SET_DOCTOR_ACCOUNT_STATUS_SUCCESS = "SET_DOCTOR_ACCOUNT_STATUS_SUCCESS";
export const SET_DOCTOR_ACCOUNT_STATUS_ERROR = "SET_DOCTOR_ACCOUNT_STATUS_ERROR";
export const DELETE_DOCTOR_INIT = "DELETE_DOCTOR_INIT";
export const DELETE_DOCTOR_SUCCESS = "DELETE_DOCTOR_SUCCESS";
export const DELETE_DOCTOR_ERROR = "DELETE_DOCTOR_ERROR";
export const ADD_TOP_RATED_DOCTOR_INIT = "ADD_TOP_RATED_DOCTOR_INIT";
export const ADD_TOP_RATED_DOCTOR_SUCCESS = "ADD_TOP_RATED_DOCTOR_SUCCESS";
export const ADD_TOP_RATED_DOCTOR_ERROR = "ADD_TOP_RATED_DOCTOR_ERROR";
export const DELETE_TOP_RATED_INIT = "DELETE_TOP_RATED_INIT";
export const DELETE_TOP_RATED_SUCCESS = "DELETE_TOP_RATED_SUCCESS";
export const DELETE_TOP_RATED_ERROR = "DELETE_TOP_RATED_ERROR";

//::::::UpdateDoctor Details:::::
export const UPDATE_DOCTOR_INIT = "UPDATE_DOCTOR_INIT";
export const UPDATE_DOCTOR_SUCCESS = "UPDATE_DOCTOR_SUCCESS";
export const UPDATE_DOCTOR_FAIL = "UPDATE_DOCTOR_FAIL";

// :::::Fetch_Doctors_By_Id:::::::
export const GET_DOCTORS_BY_ID_INIT="GET_DOCTORS_BY_ID_INIT";
export const GET_DOCTORS_BY_ID_SUCCESS="GET_DOCTORS_BY_ID_SUCCESS";
export const GET_DOCTORS_BY_ID_FAIL="GET_DOCTORS_BY_ID_FAIL";

// :::::Add New Doctor:::::
export const ADD_NEW_DOCTOR_INIT="ADD_NEW_DOCTOR_INIT";
export const ADD_NEW_DOCTOR_SUCCESS="ADD_NEW_DOCTOR_SUCCESS";
export const ADD_NEW_DOCTOR_FAIL="ADD_NEW_DOCTOR_FAIL";

//state fetching
export const GET_STATE_FETCH_INIT="GET_STATE_FETCH_INIT";
export const GET_STATE_FETCH_SUCCESS="GET_STATE_FETCH_SUCCESS";
export const GET_STATE_FETCH_FAIL="GET_STATE_FETCH_FAIL";

//country fetching
export const GET_COUNTRY_FETCH_INIT="GET_COUNTRY_FETCH_INIT";
export const GET_COUNTRY_FETCH_SUCCESS="GET_COUNTRY_FETCH_SUCCESS";
export const GET_COUNTRY_FETCH_FAIL="GET_COUNTRY_FETCH_FAIL";

//doctor category fetching
export const GET_DOCTOR_CATEGORY_INIT="GET_DOCTOR_CATEGORY_INIT";
export const GET_DOCTOR_CATEGORY_SUCCESS="GET_DOCTOR_CATEGORY_SUCCESS";
export const GET_DOCTOR_CATEGORY_FAIL="GET_DOCTOR_CATEGORY_FAIL";

//::::::Edit User Details:::::
export const UPDATE_USER_DETAILS_INIT = "UPDATE_USER_DETAILS_INIT";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAIL = "UPDATE_USER_DETAILS_FAIL";