import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  fetchDoctorFail,
  fetchDoctorSuccess,
  fetchTopRatedDoctorSuccess,
  deleteTopRatedSuccess,
  deleteTopRatedError,
  addTopRatedDoctorSuccess,
  addTopRatedDoctorError,
  deleteDoctorSuccess,
  deleteDoctorError,
  setDoctorAccountStatusSuccess,
  setDoctorAccountStatusError,
  fetchTopRatedDoctorFail,
  updateDoctorSuccess,
  updateDoctorFail,
  getDoctorDetailsByIdSuccess,
  getDoctorDetailsByIdFail,
  addDoctorDetailsFail,
  addDoctorDetailsSuccess,
  getStateFetchSuccess,
  getStateFetchFail,
  getDoctorCategoryFetchSuccess,
  getCountryFetchFail,
  getDoctorCategoryFetchFail,
  getCountryFetchSuccess,
  updateUserDetailsFail,
  updateUserDetailsSuccess,
  //plopImportAction
} from "../actions/Doctor";
import {
  DOCTOR_FETCH_INIT,
  FETCH_TOP_RATED_DOCTOR_INIT,
  DELETE_TOP_RATED_INIT,
  ADD_TOP_RATED_DOCTOR_INIT,
  DELETE_DOCTOR_INIT,
  SET_DOCTOR_ACCOUNT_STATUS_INIT,
  UPDATE_DOCTOR_INIT,
  GET_DOCTORS_BY_ID_INIT,
  GET_DOCTORS_BY_ID_SUCCESS,
  GET_DOCTORS_BY_ID_FAIL,
  ADD_NEW_DOCTOR_INIT,
  GET_STATE_FETCH_INIT,
  GET_COUNTRY_FETCH_INIT,
  GET_DOCTOR_CATEGORY_INIT,
  UPDATE_USER_DETAILS_INIT,
  //plopImportConstant
} from "redux/constants/Doctor";

import FirebaseService from "services/FirebaseService";
import { UPDATE_APP_TEXT_INIT } from "redux/constants/SettingsConstants";

export function* getDoctors() {
  yield takeEvery(DOCTOR_FETCH_INIT, function* () {
    try {
      const doctors = yield call(FirebaseService.fetchCollection, "Doctors", {
        sort: {
          attribute: "createdAt",
          order: "desc",
        },
      });
      let changeTime = doctors.map((element) => {
        var d = new Date(0);
        d.setUTCSeconds(element.createdAt.seconds);
        return {
          ...element,
          createdAt: `${d}`,
        };
      });
      yield put(fetchDoctorSuccess(changeTime));
    } catch (error) {
      console.log(
        "🚀 ~ file: Doctors.js ~ line 37 ~ yieldtakeEvery ~ error",
        error
      );
      yield put(fetchDoctorFail(error));
    }
  });
}

//get state details
export function* getStateDetails() {
  yield takeEvery(GET_STATE_FETCH_INIT, function* (action) {
    try {
      const stateDetails = yield call(FirebaseService.getStateDetails);
      
      // Log the fetched state details before dispatching
      console.log("Fetched state details:", stateDetails); 

      yield put(getStateFetchSuccess(stateDetails)); // Dispatch success action with fetched data

    } catch (err) {
      console.error("Saga: Error fetching State details:", err.message);
      yield put(getStateFetchFail(err.message)); // Dispatch failure action on error
    }
  });
}

//get country details

export function* getCountryDetails() {
  yield takeEvery(GET_COUNTRY_FETCH_INIT, function* (action) {
    try {
      const countryDetails = yield call(FirebaseService.getCountryDetails);
      
      // Log the fetched country details before dispatching
      console.log("Fetched country details:", countryDetails); 

      yield put(getCountryFetchSuccess(countryDetails)); // Dispatch success action with fetched data

    } catch (err) {
      console.error("Saga: Error fetching Country details:", err.message);
      yield put(getCountryFetchFail(err.message)); // Dispatch failure action on error
    }
  });
}

//::::::::Get Doctor Category Details:::::::

export function* getDoctorCategoryDetails() {
  yield takeEvery(GET_DOCTOR_CATEGORY_INIT, function* (action) {
    try {
      const categoryDetails = yield call(FirebaseService.getDoctorCategoryDetails);
      
      // Log the fetched category details before dispatching
      console.log("Fetched DoctorCategory details:", categoryDetails); 

      yield put(getDoctorCategoryFetchSuccess(categoryDetails)); // Dispatch success action with fetched data

    } catch (err) {
      console.error("Saga: Error fetching Doctor Category details:", err.message);
      yield put(getDoctorCategoryFetchFail(err.message)); // Dispatch failure action on error
    }
  });
}

 // ::::Edit User Details::::::
 export function* updateEditUserDetails() {
  yield takeEvery(UPDATE_USER_DETAILS_INIT, function* (action) {
   
    
    try {
      yield call(
        FirebaseService.updateEditUserDetails,
        action.userId,
        action.updatedData
      );

      console.log("Sagas/ id and data:::",action.userId,action.updatedData);
      

      if (!action.userId || !action.updatedData) {
        console.error("Invalid data in the action payload at saga edit user:", action);
        return; 
      }

      yield put(updateUserDetailsSuccess({
        id: action.userId,
        updatedData: action.updatedData
      }));
      console.log("Saga: Dispatched UPDATE_USER_DETAILS_SUCCESS");
    } catch (error) {
      console.error("Saga: Error updating doctor details:", error);

      yield put(updateUserDetailsFail(error));
    }
  });
}

export function* getTopRatedDoctor() {
  yield takeEvery(FETCH_TOP_RATED_DOCTOR_INIT, function* () {
    try {
      const listIdTopRatedDoctor = yield call(
        FirebaseService.fetchCollection,
        "TopRatedDoctor"
      );

      let convertTopRatedId = listIdTopRatedDoctor.map((item) =>
        item["doctorId"].replace(/\s/g, "")
      );
      console.log(
        "🚀 ~ file: Doctors.js ~ line 41 ~ yieldtakeEvery ~ convertTopRatedId",
        convertTopRatedId
      );
      if (convertTopRatedId.length > 10) {
        convertTopRatedId.length = 10;
      } else if (convertTopRatedId.length >= 0) {
        yield put(fetchTopRatedDoctorSuccess([]));
        //return;
      }

      const listTopRated = yield call(
        FirebaseService.fetchCollection,
        "Doctors",
        {
          queries: [
            {
              attribute: "uid",
              operator: "in",
              value: convertTopRatedId,
            },
          ],
        }
      );
      console.log(
        "🚀 ~ file: Doctors.js ~ line 50 ~ yieldtakeEvery ~ listTopRated",
        listTopRated
      );
      yield put(fetchTopRatedDoctorSuccess(listTopRated));
    } catch (error) {
      yield put(fetchTopRatedDoctorFail(error));
    }
  });
}
export function* deleteTopRated() {
  yield takeEvery(DELETE_TOP_RATED_INIT, function* (action) {
    try {
      yield call(FirebaseService.removeTopRatedDoctor, action.doctorId);
      yield put(deleteTopRatedSuccess(action.doctorId));
    } catch (error) {
      yield put(deleteTopRatedError(error.message));
    }
  });
}

export function* addTopRatedDoctor() {
  yield takeEvery(ADD_TOP_RATED_DOCTOR_INIT, function* (action) {
    try {
      yield call(FirebaseService.addTopRatedDoctor, action.doctorId);
      yield put(addTopRatedDoctorSuccess(action.doctorId));
    } catch (error) {
      console.log(
        "🚀 ~ file: Doctors.js ~ line 91 ~ yieldtakeEvery ~ error",
        error
      );

      yield put(addTopRatedDoctorError(error.message));
    }
  });
}
export function* deleteDoctor() {
  yield takeEvery(DELETE_DOCTOR_INIT, function* (action) {
    try {
      console.log(
        "🚀 ~ file: Doctors.js ~ line 108 ~ yieldtakeEvery ~ action",
        action
      );

      yield call(FirebaseService.deleteDoctorAccount, action.doctorId);
      yield put(deleteDoctorSuccess(action.doctorId));
    } catch (error) {
      console.log(
        "🚀 ~ file: Doctors.js ~ line 117 ~ yieldtakeEvery ~ error",
        error
      );
      yield put(addTopRatedDoctorError(error.message));
    }
  });
}
export function* setDoctorAccountStatus() {
  yield takeEvery(SET_DOCTOR_ACCOUNT_STATUS_INIT, function* (action) {
    try {
      yield call(
        FirebaseService.setDoctorAccountStatus,
        action.doctorId,
        action.status
      );
      yield put(setDoctorAccountStatusSuccess(action.doctorId, action.status));
    } catch (error) {
      yield put(setDoctorAccountStatusError(error));
    }
  });
}


// ::::Edit Doctors Details::::::
export function* updateEditDoctorDetails() {
  yield takeEvery(UPDATE_DOCTOR_INIT, function* (action) {
   
    
    try {
      yield call(
        FirebaseService.updateEditDoctorDetails,
        action.doctorId,
        action.updatedData
      );

      console.log("Sagas/ id and data:::",action.doctorId,action.updatedData);
      

      if (!action.doctorId || !action.updatedData) {
        console.error("Invalid data in the action payload:", action);
        return; 
      }

      yield put(updateDoctorSuccess({
        id: action.doctorId,
        updatedData: action.updatedData
      }));
      console.log("Saga: Dispatched UPDATE_DOCTOR_SUCCESS");
    } catch (error) {
      console.error("Saga: Error updating doctor details:", error);

      yield put(updateDoctorFail(error));
    }
  });
}

    // :::::Get Doctors By Id::::::
    export function* getDoctorDetailsById() {
      yield takeEvery(GET_DOCTORS_BY_ID_INIT, function* (action) {
        try {
        
          if (!action.doctorId) {
            console.error("Invalid doctorId in the (getDoctorDetailsById) action payload:", action);
            return; 
          }
  
          const doctorDetails = yield call(FirebaseService.getDoctorDetailsById, action.doctorId);
    
          // Dispatch success action with the fetched data
          yield put(getDoctorDetailsByIdSuccess(doctorDetails));
          console.log("Saga: Dispatched GET_DOCTORS_BY_ID_SUCCESS with data:", doctorDetails);
    
        } catch (err) {
          // Log and dispatch failure action with the error message
          console.error("Saga: Error fetching doctor details:", err.message);
    
          // Passing only the error message to the fail action
          yield put(getDoctorDetailsByIdFail(err.message));
        }
      });
    }


    // :::::::Add New Doctor Details::::::::
    export function* addNewDoctorDetails(){
      yield takeEvery(ADD_NEW_DOCTOR_INIT,function* (action){
        try{
          const { selectedId,doctorDetails} = action.payload;
          console.log("SelectedId..",selectedId);
          console.log("doctorDetails..",doctorDetails);
          
          
         if (!doctorDetails) {
            console.log("Invalid DoctorDetails in Doctor.js/Saga::::", doctorDetails);
            return;
           }

            yield call(FirebaseService.addNewDoctorDetails, selectedId, doctorDetails);

           console.log("doctorDetails in Saga:", doctorDetails);


           yield put(addDoctorDetailsSuccess({ ...doctorDetails, selectedId }));
           console.log("Saga: Dispatched ADD_NEW_DOCTOR_SUCCESS with doctorId:", doctorDetails, "and selectedId:", selectedId);

        }catch(error){
          console.error("Saga: Error adding new doctor:", error);
          yield put(addDoctorDetailsFail(error));
        }
      })
    }
    


//plopSaga

export default function* rootSaga() {
  yield all([
    fork(getDoctors),
    fork(getTopRatedDoctor),
    fork(deleteTopRated),
    fork(addTopRatedDoctor),
    fork(deleteDoctor),
    fork(setDoctorAccountStatus),
    fork(updateEditDoctorDetails),
    fork(getDoctorDetailsById),
    fork(addNewDoctorDetails),
    fork(getStateDetails),
    fork(getDoctorCategoryDetails),
    fork(updateEditUserDetails),
    fork(getCountryDetails)
    //plopExport
  ]);
}