import {
  SET_DOCTOR_ACCOUNT_STATUS_INIT,
  SET_DOCTOR_ACCOUNT_STATUS_SUCCESS,
  SET_DOCTOR_ACCOUNT_STATUS_ERROR,
  DELETE_DOCTOR_INIT,
  DELETE_DOCTOR_SUCCESS,
  DELETE_DOCTOR_ERROR,
  ADD_TOP_RATED_DOCTOR_INIT,
  ADD_TOP_RATED_DOCTOR_SUCCESS,
  ADD_TOP_RATED_DOCTOR_ERROR,
  DELETE_TOP_RATED_INIT,
  DELETE_TOP_RATED_SUCCESS,
  DELETE_TOP_RATED_ERROR,
  DOCTOR_FETCH_FAIL,
  DOCTOR_FETCH_INIT,
  DOCTOR_FETCH_SUCCESS,
  FETCH_TOP_RATED_DOCTOR_INIT,
  FETCH_TOP_RATED_DOCTOR_FAIL,
  FETCH_TOP_RATED_DOCTOR_SUCCESS,
  UPDATE_DOCTOR_INIT,
  UPDATE_DOCTOR_SUCCESS,
  UPDATE_DOCTOR_FAIL,
  GET_DOCTORS_BY_ID_INIT,
  GET_DOCTORS_BY_ID_SUCCESS,
  GET_DOCTORS_BY_ID_FAIL,
  ADD_NEW_DOCTOR_INIT,
  ADD_NEW_DOCTOR_SUCCESS,
  ADD_NEW_DOCTOR_FAIL,
  GET_STATE_FETCH_INIT,
  GET_STATE_FETCH_SUCCESS,
  GET_STATE_FETCH_FAIL,
  GET_COUNTRY_FETCH_INIT,
  GET_COUNTRY_FETCH_SUCCESS,
  GET_COUNTRY_FETCH_FAIL,
  GET_DOCTOR_CATEGORY_INIT,
  GET_DOCTOR_CATEGORY_SUCCESS,
  GET_DOCTOR_CATEGORY_FAIL,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAIL,
  UPDATE_USER_DETAILS_INIT,
} from "../constants/Doctor";

export const fetchDoctorInit = () => ({
  type: DOCTOR_FETCH_INIT,
});

export const fetchDoctorSuccess = (data) => ({
  type: DOCTOR_FETCH_SUCCESS,
  data,
});

export const fetchDoctorFail = (err) => ({
  type: DOCTOR_FETCH_FAIL,
  err,
});

export const fetchTopRatedDoctorInit = () => ({
  type: FETCH_TOP_RATED_DOCTOR_INIT,
});

export const fetchTopRatedDoctorFail = (topRatedError) => ({
  type: FETCH_TOP_RATED_DOCTOR_FAIL,
  topRatedError,
});

export const fetchTopRatedDoctorSuccess = (topRatedDoctor) => ({
  type: FETCH_TOP_RATED_DOCTOR_SUCCESS,
  topRatedDoctor,
});

export const deleteTopRatedInit = (doctorId) => ({
  type: DELETE_TOP_RATED_INIT,
  doctorId,
});

export const deleteTopRatedSuccess = (doctorId) => ({
  type: DELETE_TOP_RATED_SUCCESS,
  doctorId,
});

export const deleteTopRatedError = (error) => ({
  type: DELETE_TOP_RATED_ERROR,
  error,
});

export const addTopRatedDoctorInit = (doctorId) => ({
  type: ADD_TOP_RATED_DOCTOR_INIT,
  doctorId,
});

export const addTopRatedDoctorSuccess = (doctorId) => ({
  type: ADD_TOP_RATED_DOCTOR_SUCCESS,
  doctorId,
});

export const addTopRatedDoctorError = (error) => ({
  type: ADD_TOP_RATED_DOCTOR_ERROR,
  error,
});

export const deleteDoctorInit = (doctorId) => ({
  type: DELETE_DOCTOR_INIT,
  doctorId: doctorId,
});

export const deleteDoctorSuccess = (doctorId) => ({
  type: DELETE_DOCTOR_SUCCESS,
  doctorId,
});

export const deleteDoctorError = (error) => ({
  type: DELETE_DOCTOR_ERROR,
  error,
});

export const setDoctorAccountStatusInit = (doctorId, status) => ({
  type: SET_DOCTOR_ACCOUNT_STATUS_INIT,
  doctorId,
  status,
});

export const setDoctorAccountStatusSuccess = (doctorId, status) => ({
  type: SET_DOCTOR_ACCOUNT_STATUS_SUCCESS,
  doctorId,
  status,
});

export const setDoctorAccountStatusError = (error) => ({
  type: SET_DOCTOR_ACCOUNT_STATUS_ERROR,
  error,
});


  // ::::Edit Doctor Details::::::

  export const updateDoctorInit = (doctorId, updatedData) => {
   console.log("Doctorid and Upadted (updateDoctorInit) data in Doctors.js/actions",doctorId,updatedData);
    return {
      type: UPDATE_DOCTOR_INIT,
      doctorId,
      updatedData,
    };
  };

  export const updateDoctorSuccess = (doctorId, updatedData) => {
    console.log("Doctorid and Upadted (updateDoctorSuccess) data in Doctors.js/actions",doctorId,updatedData);
    return {
      type: UPDATE_DOCTOR_SUCCESS,
      doctorId,
      updatedData,
    };
  };

  export const updateDoctorFail=(error)=>({
    type:UPDATE_DOCTOR_FAIL,
    error,
  });


  // :::::::Fetch_Doctors_By_Id::::::

  export const getDoctorByIdInit=(doctorId)=>{
    console.log("Get DoctorId to fetch in Doctor.js/action::::",doctorId);
    return{
      type:GET_DOCTORS_BY_ID_INIT,
      doctorId,
    };
  };

  export const getDoctorDetailsByIdSuccess=(doctorDetails)=>{
    console.log("Get DoctorId to fetch (Success...) in Doctor.js/action::::",doctorDetails);
    const fetchedDetails={...doctorDetails};
    // console.log("doctorDetails type:", typeof doctorDetails);
    // console.log("doctorDetails keys:", Object.keys(doctorDetails));
    
    return{
      type:GET_DOCTORS_BY_ID_SUCCESS,
      doctor: fetchedDetails,
    }
  };

  export const getDoctorDetailsByIdFail =(error)=>{
    console.log("Get DoctorId to fetch (Fail...) in Doctor.js/action::::",error);
    return{
      type:GET_DOCTORS_BY_ID_FAIL,
      error,
    }
  };

  //state fetching
  export const getStateFetchInit = () => ({
    type: GET_STATE_FETCH_INIT,
  });
  
  export const getStateFetchSuccess = (stateDetails) => ({
    type: GET_STATE_FETCH_SUCCESS,
    stateDetails,
    
  });
  
  export const getStateFetchFail = (error) => ({
    type: GET_STATE_FETCH_FAIL,
    payload: error,
  });


  // ::::::Add New Doctor Details::::::

  export const addDoctorInit=(selectedId,doctorDetails)=>{
    console.log("doctorDetails in Action::::::::",doctorDetails);
    return {
      type: ADD_NEW_DOCTOR_INIT,
      payload: {
        selectedId,
        doctorDetails
      },
    };
  };

  export const addDoctorDetailsSuccess=(selectedId,doctorDetails)=>{
    return{
      type:ADD_NEW_DOCTOR_SUCCESS,
      payload: {
        selectedId,
        doctorDetails
      },
    };
  };

  export const addDoctorDetailsFail=(error)=>{
    return{
      type:ADD_NEW_DOCTOR_FAIL,
      payload:error,
    }
  };

   // :::::::Country Fetching:::::::

   export const getCountryFetchInit = () => ({
    type: GET_COUNTRY_FETCH_INIT,
  });
  
  export const getCountryFetchSuccess = (countryDetails) => ({
    type: GET_COUNTRY_FETCH_SUCCESS,
    countryDetails,
    
  });
  
  export const getCountryFetchFail = (error) => ({
    type: GET_COUNTRY_FETCH_FAIL,
    payload: error,
  });
  
// ::::::::Doctor Category fetching:::::::

export const getDoctorCategoryFetchInit = () => ({
  type: GET_DOCTOR_CATEGORY_INIT,
});

export const getDoctorCategoryFetchSuccess = (categoryDetails) => ({
  type: GET_DOCTOR_CATEGORY_SUCCESS,
  categoryDetails,
  
});

export const getDoctorCategoryFetchFail = (error) => ({
  type: GET_DOCTOR_CATEGORY_FAIL,
  payload: error,
});

// ::::Edit User Details::::::

export const updateUserDetailsInit = (userId, updatedData) => {
  console.log("userId and Upadted (updateUserInit) data in Doctors.js/actions",userId,updatedData);
   return {
     type: UPDATE_USER_DETAILS_INIT,
     userId,
     updatedData,
   };
 };

 export const updateUserDetailsSuccess = (userId, updatedData) => {
   console.log("UserId and Upadted (updateUserSuccess) data in Doctors.js/actions",userId,updatedData);
   return {
     type: UPDATE_USER_DETAILS_SUCCESS,
     userId,
     updatedData,
   };
 };

 export const updateUserDetailsFail=(error)=>({
   type:UPDATE_USER_DETAILS_FAIL,
   error,
 });