import {
  SET_DOCTOR_ACCOUNT_STATUS_INIT,
  SET_DOCTOR_ACCOUNT_STATUS_SUCCESS,
  SET_DOCTOR_ACCOUNT_STATUS_ERROR,
  DELETE_DOCTOR_INIT,
  DELETE_DOCTOR_SUCCESS,
  DELETE_DOCTOR_ERROR,
  ADD_TOP_RATED_DOCTOR_INIT,
  ADD_TOP_RATED_DOCTOR_SUCCESS,
  ADD_TOP_RATED_DOCTOR_ERROR,
  DELETE_TOP_RATED_INIT,
  DELETE_TOP_RATED_SUCCESS,
  DELETE_TOP_RATED_ERROR,
  DOCTOR_FETCH_INIT,
  DOCTOR_FETCH_SUCCESS,
  DOCTOR_FETCH_FAIL,
  FETCH_TOP_RATED_DOCTOR_INIT,
  FETCH_TOP_RATED_DOCTOR_SUCCESS,
  FETCH_TOP_RATED_DOCTOR_FAIL,
  UPDATE_DOCTOR_INIT,
  UPDATE_DOCTOR_SUCCESS,
  UPDATE_DOCTOR_FAIL,
  GET_DOCTORS_BY_ID_INIT,
  GET_DOCTORS_BY_ID_SUCCESS,
  GET_DOCTORS_BY_ID_FAIL,
  ADD_NEW_DOCTOR_INIT,
  ADD_NEW_DOCTOR_SUCCESS,
  ADD_NEW_DOCTOR_FAIL,
  GET_STATE_FETCH_INIT,
  GET_STATE_FETCH_SUCCESS,
  GET_STATE_FETCH_FAIL,
  GET_COUNTRY_FETCH_SUCCESS,
  GET_COUNTRY_FETCH_INIT,
  GET_COUNTRY_FETCH_FAIL,
  GET_DOCTOR_CATEGORY_INIT,
  GET_DOCTOR_CATEGORY_SUCCESS,
  GET_DOCTOR_CATEGORY_FAIL,
  UPDATE_USER_DETAILS_INIT,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAIL,
} from "redux/constants/Doctor";

const initialState = {
  data: [],
  topRatedDoctor: [],
  loading: false,
  topRatedLoading: false,
  error: null,
  success: false,
  delete: false,
  topRatedSuccess: false,
  topRatedDelete: false,
  topRatedError: false,
};

const doctors = (state = initialState, action) => {
  switch (action.type) {
    case DOCTOR_FETCH_INIT:
      return { ...state, loading: true };
    case DOCTOR_FETCH_SUCCESS:
      return { ...state, success: true, data: action.data, loading: false };
    case DOCTOR_FETCH_FAIL:
      return { ...state, error: action.error };
    case FETCH_TOP_RATED_DOCTOR_INIT:
      return { ...state, topRatedLoading: true };
    case FETCH_TOP_RATED_DOCTOR_SUCCESS:
      return {
        ...state,
        topRatedSuccess: true,
        topRatedDoctor: action.topRatedDoctor,
        topRatedLoading: false,
      };
    case FETCH_TOP_RATED_DOCTOR_FAIL:
      return {
        ...state,
        topRatedError: action.topRatedError,
        topRatedLoading: false,
      };
    case DELETE_TOP_RATED_INIT:
      return { ...state, topRatedLoading: true };
    case DELETE_TOP_RATED_SUCCESS:
      return {
        ...state,
        success: true,
        topRatedDoctor: state.topRatedDoctor.filter(
          (elem) => elem.id !== action.doctorId
        ),
        topRatedLoading: false,
      };
    case DELETE_TOP_RATED_ERROR:
      return { ...state, topRatedError: action.error, topRatedLoading: false };
    case ADD_TOP_RATED_DOCTOR_INIT:
      return { ...state, loading: true };
    case ADD_TOP_RATED_DOCTOR_SUCCESS:
      let doctorAdded = state.data.find(
        (object) => object.id === action.doctorId
      );
      console.log(
        "🚀 ~ file: Doctor.js ~ line 76 ~ doctors ~ doctorAdded",
        doctorAdded
      );
      return {
        ...state,
        success: true,
        topRatedDoctor: [...state.topRatedDoctor, doctorAdded],
        loading: false,
      };
    case ADD_TOP_RATED_DOCTOR_ERROR:
      return { ...state, error: action.error };
    case DELETE_DOCTOR_INIT:
      return { ...state, loading: true };
    case DELETE_DOCTOR_SUCCESS:
      return {
        ...state,
        success: true,
        data: state.data.filter((elem) => elem.id !== action.doctorId),
        loading: false,
      };
    case DELETE_DOCTOR_ERROR:
      return { ...state, error: action.error, loading: false };
    case SET_DOCTOR_ACCOUNT_STATUS_INIT:
      return { ...state, loading: true };
    case SET_DOCTOR_ACCOUNT_STATUS_SUCCESS:
      console.log("kepanggil kok");
      console.log(
        "🚀 ~ file: Doctor.js ~ line 106 ~ doctors ~ action.docot",
        action.doctorId
      );
      let objIndex = state.data.findIndex((obj) => obj.id === action.doctorId);
      let newArray = state.data;
      newArray[objIndex].accountStatus = action.status;
      // console.log(
      //   "🚀 ~ file: Doctor.js ~ line 106 ~ doctors ~ newArray[objIndex].accountStatus",
      //   newArray[objIndex].accountStatus
      // );

      return { ...state, success: true, data: newArray, loading: false };
    case SET_DOCTOR_ACCOUNT_STATUS_ERROR:
      return { ...state, error: action.error, loading: false };



  //  :::::Editing Doctor Deails::::

     case UPDATE_DOCTOR_INIT:
      return { ...state, loading: true };
      
      case UPDATE_DOCTOR_SUCCESS:   
         if (!action.payload || !action.payload.id || !action.payload.updatedData) {
           console.error("Invalid payload:", action.payload);
         return state; 
        // Return the current state if data is invalid
      }
        const updatedDoctorIndex = state.data.findIndex(
          (doctor) => doctor.id === action.payload.id
        );
        // Check if the doctor was found
        if (updatedDoctorIndex === -1) {
          return {
            ...state,
            error: 'Doctor not found',
            loading: false,
          };
        }

        //:::::Create a new list of doctors with the updated data::::

        const updatedDoctorList = [...state.data];
        updatedDoctorList[updatedDoctorIndex] = action.payload; 
         // action.payload should contain the updated doctor details
        return {
          ...state,
          success: true,
          data: updatedDoctorList,
          loading: false,
        };
      case UPDATE_DOCTOR_FAIL:
        return { ...state, error: action.payload, loading: false };

        //:::::Get Doctor Details by Id :::::

      case GET_DOCTORS_BY_ID_INIT:
          return{...state,
            loading:true };

      case GET_DOCTORS_BY_ID_SUCCESS:
          return {
            ...state,
            doctor: action.doctor,
            loading:false,
            error:null
          };

      case GET_DOCTORS_BY_ID_FAIL:
          return {
          ...state,
          loading: false,          
          error: action.error,     
          };

     //:::::Add New Doctor::::::
     
       case ADD_NEW_DOCTOR_INIT:
          return{
            ...state,
            loading:true,
          };
         
       case ADD_NEW_DOCTOR_SUCCESS:
          if(!action.payload || typeof action.payload !== 'object'|| !action.payload.doctorDetails){
            return state;
          }else{
            return {
              ...state,
              success: true,
              data: action.payload.doctorDetails,
              selectedId: action.selectedId,
              loading: false,
            }
          };
         
        case ADD_NEW_DOCTOR_FAIL:
          return{
            ...state,
            error: action.payload,
            loading: false,
            success: false,
          }
          
          //fetch state data
          case GET_STATE_FETCH_INIT:
      return {
        ...state,
        loading: true, 
      };
    
    case GET_STATE_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        stateDetails: action.stateDetails, 
        error: null,
      };
    
    case GET_STATE_FETCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload, 
      };

      //:::::Fetch CountryData::::::

    case GET_COUNTRY_FETCH_INIT:
      return {
        ...state,
        loading: true, 
      };
    
    case GET_COUNTRY_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        countryDetails: action.countryDetails, 
        error: null,
      };
    
    case GET_COUNTRY_FETCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload, 
      };

      // ::::::DoctorCategory Fetch::::::

      case GET_DOCTOR_CATEGORY_INIT:
      return {
        ...state,
        loading: true, 
      };
    
    case GET_DOCTOR_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        categoryDetails: action.categoryDetails, 
        error: null,
      };
    
    case GET_DOCTOR_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload, 
      };

      //  :::::Editing user Deails::::

     case UPDATE_USER_DETAILS_INIT:
      return { ...state,
         loading: true };
      
      case UPDATE_USER_DETAILS_SUCCESS:   
         if (!action.payload || !action.payload.id || !action.payload.updatedData) {
           console.error("Invalid payload at Edit user Reducer::::", action.payload);
         return state; 
        // Return the current state if data is invalid
      }
        const updatedUserIndex = state.data.findIndex(
          (user) => user.id === action.payload.id
        );

        // Check if the doctor was found
        if (updatedUserIndex === -1) {
          return {
            ...state,
            error: 'User not found',
            loading: false,
          };
        }

        //:::::Create a new list of doctors with the updated data::::

        const updatedUserList = [...state.data];
        updatedUserList[updatedUserIndex] = {
          ...updatedUserList[updatedUserIndex], 
          ...action.payload.updatedData, 
        };
        return {
          ...state,
          success: true,
          data: updatedUserList,
          loading: false,
        };

      case UPDATE_USER_DETAILS_FAIL:
        return { ...state,
           error: action.payload,
           loading: false 
          };



    //plopImport
    default:
      return state;
  }
};
export default doctors;